import React from 'react';
import CityGuide from '../../../components/guides/CityComponent';
import Footer from '../../../components/FooterComponent';

import { Prague } from '../../../guides/countries/czech-republic/prague';

function PraguePage(props) {
    return (
        <div>
            <CityGuide city={ Prague } pathname={ props.location.pathname } urlBack="/travel-guides/europe" />
            <Footer />
        </div>
    );
}

export default PraguePage;
