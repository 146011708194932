import React from 'react';

export const Prague = {
    "name": "Prague",
    "link": "prague",
    "country": "Czech Republic",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50380242421_9a391df2ac_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380242421_14618b9a03_o.jpg",
    "caption": "The Bohemian Gem of Eastern Europe",

    "description": "The centuries-old buildings, the quirky art, the tasty beer, the history, the romanticism of the city... these are just some reasons to make you believe that Prague is a great destination for you. With so much to see and do, you could spend hours navigating the streets and have a view of the local culture.",

    "nrDays":<p>
            Prague is a big city and it has a lot of attractions and nice spots to relax. We recommend <b>3 days</b> in the city, since the third day could be used to relax at the city parks or to make a day trip to some nice place at the surroundings.
        </p>,

    "whenVisit": <p>
            Summer is the high season. It is at this time when the city is full of tourists. Personally, we recommend to avoid the months of July and August and try to visit the city at <b>June and September</b>. You will still get good weather and way less people.
            <br/><br/>
            During the <b>Eastern and Christmas</b> season the city is also beautiful. Full of traditional markets in all the main squares, this will make you feel like you are in a fairy tail. However the weather is not ideal at this time.
            </p>,

    "mobility": <p>
        Public transportation in Prague is really good. There are subways and trams, both served by the same ticket system. You can buy
        from <a href="https://www.dpp.cz/en/fares/fare-pricelist#1-1" target="_blank" rel="noopener noreferrer">a 30 minute ticket, similar to a "single ride" to a 24h ticket</a>" (around 4,20€). The 24h ticket starts counting from the moment
        that you use it for the first time. However, to visit most of the attractions you can do so walking, and at the same time embrace the beauty of the narrow streets of the city.
        Taxi and Uber are other options, but more expensive.
        <br/><br/>
        As Prague is very well connected with the rest of the world it’s easy to get to the city by plane, train, bus or car. If you choose the
        option of the plane, to get to the city centre you can catch a bus by around 1,25€ or get a taxi/uber.
    </p>,

    "safety": <p>
        Most visitors to the country don't experience any kind of difficulties, but as in all big cities, you should always be aware of street crime and petty theft.
        Always exchange currency at a currency exchange office or bank, never on the street as this money is sometimes counterfeit. You can use Revolut in most of places too.
        </p>,

    "itinerary": {
        "description": "Prague has a lot to offer but all the big attractions are relatively close to each other. We will suggest here a 3-day itinerary in which you could use the last day for a day trip or to relax in the street parks.",
        "days": [
            {
                "description": <p>
                    For the first day we suggest you start with a <b>free walking tour</b> by the Old Town. It is a different experience
                    and you will learn a little more about the city's culture. This tour will lead you through all the major spots in town, as the
                    <b> Old Town Square, Charles Bridge, Jewish Quarter, Prague Castle and St. Virus Cathedral.</b> After the tour, ask your
                    guide a good place to taste traditional food. Probably during your tour you will make a stop at the castle to taste the famous
                    beer, but if not, don't forget to return there to taste it, it's well worth. After lunch visit the <b>Petrin Tower</b> and take a time to relax
                    at the park there. At the end of the day go to the river and take a small boat ride to enjoy an amazing sunset.
                    <br/><br/>
                    At night enjoy the city life and visit one of the most famous night clubs in Europe, the <b>Karlovy Lazne</b>.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50498533811_1ecf8bba48_o.jpg",
                "caption": "Charles Bridge view from the River."
            },
            {
                "description": <p>
                        At the second day, start it with a visit to the <b>Wenceslas Square</b> and to the <b>National Museum</b> located close to the square.
                        Taste a good traditional food at the <b>Old Town</b> and at the afternoon visit the famous toy's shop of the city, the <b> Lennon Wall </b>
                        and the <b>Dancing House</b>.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50497820303_0708645cdd_o.jpg",
                "caption": "Lennon Wall."
            },
            {
                "description": <p>
                    For your last day you have two options. Either take a day trip to the <b>Bohemian Switzerland National Park</b> or relax and
                    enjoy the spots that you could have missed during the fist two days, such as the <b>Vysehrad</b>, the <b>Namesti Miru</b> or the <b>Grebovka and Vinohrady neighborhood</b>.
                    If you're a nature fan we surely recommend you the first one. If you're more of a city person the second choice is best for you.
                </p>,
                "image": "https://live.staticflickr.com/65535/50497820293_be2e0996dd_o.jpg",
                "caption": "Bohemian Switzerland National Park."
            }
        ]
    },

    "highlights": [
        {
            "name": "Prague Castle and Saint Vitus Cathedral",
            "description": <p>
                Prague Castle sits in a hill that overlooks the <b>Vltava River</b>, in a neighbourhood called Hradcany.
                It is a vast complex with buildings dating back to the 9th century. The complex includes <b>the Castle,
                 the Gothic Saint Vitus Cathedral, the Romanesque Basilica of St. George, and the Spanish Hall</b>.
            </p>,
            "image": "https://live.staticflickr.com/65535/50497820278_bc0d332fbe_o.jpg"
        },
        {
            "name": "Charles Bridge",
            "description": <p>
                Also known as <b>Karlov Most</b> this is a large stone bridge stretching across the Vltava River between the old and the new
                towns. It is lined by Baroque statues in all its path.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498683482_668b09509f_o.jpg"
        },
        {
            "name": "Wenceslas Square",
            "description": <p>This place, the main square of Prague, is more of a boulevard than a regular plaza. It was renovated
                recently, so you will find here a lot of trees and places to sit and observe the beauty of the square.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498683477_0a23b12d20_o.jpg"
        },
        {
            "name": "Petrin Tower",
            "description": <p>Petrin Tower is located on Petrin Hill, in Mala Strana. It is a 63 meters tall structure that resembles the Eiffel Tower.
                It's a popular viewpoint over the castle and the river and on a sunny day you can see beyond Prague into the Bohemian hinterland. </p>,
            "image": "https://live.staticflickr.com/65535/50498533681_4492711b43_o.jpg"
        },
        {
            "name": "Jewish Quarter",
            "description": <p>
                Known also as the Jewish ghetto or Josefov, this place is located between the Old Town Square and the River. This area is famous
                for all its synagogues and cemeteries.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498683417_6605ba85ab_o.jpg"
        },
        {
            "name": "Old Town Square",
            "description": <p>
                In the very centre of Prague you will find the Old Town Square, one of the most beautiful squares in the world.
                Close to the square you will find a lot of famous buildings and monuments, such as the <b>Old Town Hall, Church of Our
                Lady before Týn, Baroque Church of St. Nicholas, the Rococo Kinsky Palace, the Gothic House at the Stone Bell,
                and the monument of Jan Hus.</b>
            </p>,
            "image": "https://live.staticflickr.com/65535/50498533626_4a6f07c59b_o.jpg"
        },
        {
            "name": "Astronomical Clock",
            "description": <p>Close to the Old Town Square you will find here the Prague's gem, <b>the Astronomical Clock</b>, which makes it the oldest
            still working medieval astronomical clock in the world.
            </p>,
            "image": "https://live.staticflickr.com/65535/50498533611_53b73dd87b_o.jpg"
        },
        {
            "name": "Parizska Street",
            "description": <p>Parizska Street is the most expensive street in Prague where on a short stretch, you can find luxurious and prestigious shops. </p>,
            "image": "https://live.staticflickr.com/65535/50497820198_293c7ce4a3_o.jpg"
        },
        {
            "name": "Dancing House",
            "description": <p>The Dancing House is a modern building in Prague’s new town, and despite the fact that it is not as old as its surrounding buildings, it is still worth a visit.</p>,
            "image": "https://live.staticflickr.com/65535/50498683342_188c86f2f7_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            One important thing to do in Prague is to taste the local cuisine! You can opt to do a food walking tour to learn more about it, or ask from the locals the best places to sample.
            <br/><br/>
            For a cheap meal you can always look for a good restaurant on <a href="https://www.thefork.com/" target="_blank" rel="noopener noreferrer">The Fork.</a> If you are searching for the best dishes, we give you an idea of what to sample.
            </p>,

        "restaurants": [
            {
                "name": "Pivnice U Jary",
                "description": "With great food, service and beer this cheap place will give you a good vibe about the city.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50498533546_a962bb2b2a_o.jpg"
            },
            {
                "name": "Velka Klasterni Restaurace",
                "description": "Also famous by its beer, this place has friendly staff and good traditional food to taste.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498533501_33f85dcacf_o.jpg"
            },
            {
                "name": "Vaclavka Pizza Restaurant",
                "description": "A nice Italian restaurant on the streets of Prague. The pizza and the risotto are two good choices here.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50498533456_35e2acfc26_o.jpg"
            },
            {
                "name": "Restaurant Pepř a Sůl",
                "description": "A little dislocated from the city centre, but the steak here is wonderful and has really friendly staff. Well worth the trip and clearly popular with locals.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50497820093_38046db369_o.jpg"
            },
            {
                "name": "U Kroka",
                "description": "Near to Vyšehrad, this is one of the best quoted restaurants in the city. With a creative menu and local cuisine you will certainly enjoy your experience here.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50498683192_de2b6f26c4_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
               Prague has plenty of accommodation options, from hostels to five star hotels. As a tourist center you will not have
               difficulties to find out the place that is more suitable for you.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget.
        </p>,

        "budget": [
            {
                "name": "Hostel Boudnik",
                "description": "This Hostel is a 15 minute walk from the Wenceslau Square and has a sharing Kitchen and free WiFi for all guests.",
                "image": "https://live.staticflickr.com/65535/50497820063_db2d08de85_o.jpg",
                "link": "/"
            },
            {
                "name": "a&o Prague Rhea",
                "description": "A little more dislocated from the city center but with cheaper prices this hotel has very nice rooms and friendly service.",
                "image": "https://live.staticflickr.com/65535/50498533331_4d90b14a78_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "NYX Hotel Prague by Leonardo Hotels",
                "description": "At the city centre this place offers a nice decoration and very clean and large rooms. ",
                "image": "https://live.staticflickr.com/65535/50498533321_f370ca5423_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Augustine, a Luxury Collection Hotel, Prague",
                "description": "This is really a luxurious hotel. Located close to the Charles Bridge and Wallenstein Gardens the large hotel rooms offer a nice view to the city.",
                "image": "https://live.staticflickr.com/65535/50497819988_a005a11bce_o.jpg",
                "link": "/"
            }
        ]
    }
}